import Vue from 'vue'
import App from './App.vue'
import bootstrap from 'bootstrap-vue'

// Router
import router from './router/router.js'

// Sass
import '@/assets/sass/_main.scss'

/* add font awesome icon component */
import './plugins/font-awesome.js'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

// i18n
import i18n from '@/plugins/i18n.js'

// Title
import titleMixin from './plugins/titleMixin'
Vue.mixin(titleMixin)

Vue.use(bootstrap)
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
