<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="faded">
      <b-navbar-brand href="#">
        <div class="navbarLogo">
          <img src="@/assets/images/logoFSV.png" class="d-inline-block align-middle" alt="FSV Logo">
        </div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">     
          <b-nav-item>
            <router-link to="/">
              <b-button class="px-3" variant="link">{{ $t('navbar.home') }}</b-button>
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/about">
              <b-button class="px-3" variant="link">{{ $t('navbar.about') }}</b-button>
            </router-link>
          </b-nav-item>        
          <b-nav-item-dropdown id="dropdown-nav-1" class="py-2 px-3" no-caret>
            <template #button-content>
              <span>
                {{ $t('navbar.ourServices') }}
                <font-awesome-icon icon="fa-solid fa-chevron-down" />
              </span>
            </template>
            <b-dropdown-item style="font-weight-bold">
              <router-link to="/ground-handling">
                {{ $t('navbar.groundHandling') }}
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item >
              <router-link to="/aeronautical-operations-center">
                {{ $t('navbar.aeroOperCenter') }}
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item >
              <router-link to="/travel-services">
                {{ $t('navbar.travelServices') }}
              </router-link>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item>
            <router-link to="/contact-us">
              <b-button class="px-3" pill variant="outline-secondary">{{ $t('navbar.requestQuote') }}</b-button>
            </router-link>
          </b-nav-item>
          <b-nav-item-dropdown id="dropdown-nav-2" class="py-2 px-3" no-caret>
          <template #button-content>
            <span> 
              <font-awesome-icon icon="fa-solid fa-globe" />
              {{ $t('navbar.lang') }}
              <font-awesome-icon icon="fa-solid fa-chevron-down" />
            </span>
          </template>
            <b-dropdown-item href="#" @click="changeLang('en')">{{ $t('navbar.eng') }}</b-dropdown-item>
            <b-dropdown-item href="#" @click="changeLang('es')">{{ $t('navbar.spa') }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
  export default {
    name: 'NavBar',
    methods: {
      changeLang(lang) {
        this.$i18n.locale = lang
      }
    }
  }
</script>

<style lang="scss" scope>
  @import '@/assets/sass/variables/_colors.scss';
  .navbarLogo > img {
    padding-left: 45px;
    height: 120px;
  }

  ul.navbar-nav {
    .btn {
      font-weight: 600;
      color: $primary-blue; 
    }
    li {
      a {
        font-weight: 600;
      }
    }
  }

  #dropdown-nav-1 {
    a {
      color: $primary-red;
    }
    ul.dropdown-menu{
      width: 300px;
      li {
        text-align: left;
        a {
          color: $primary-blue; 
          font-weight: 600;
        }
      }
    }
  }

  #dropdown-nav-2 {
    a {
      color: $primary-blue;
    }
    ul.dropdown-menu {
      width: 50px;
    }
  }
  

  .dropdown-item {
    white-space: initial !important;
  }
</style>