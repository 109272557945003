<template>
  <div id="app">
    <NavBar />

    <router-view />

    <FooterItem />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterItem from "@/components/FooterItem.vue";
export default {
  name: 'App',
  components: {
    NavBar,
    FooterItem
  }
}
</script>

<style>
</style>
