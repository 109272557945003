<template>
  <div class="footer">
    <b-container>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="2">
          <div class="footer-logo">
          <img src="@/assets/images/logo-white.png" alt="FSV Services Logo">
            <div class="footer-logo__text">
              <p>{{ $t('footer.allRightsReserved') }} 2022</p>
            </div>
          </div>
        </b-col>
        <b-col cols="4" sm="6" md="6" lg="2">
          <div class="footer-links">
            <ul>
              <router-link to="/"><li>{{ $t('footer.home') }}</li></router-link>
              <router-link to="/about"><li>{{ $t('footer.about') }}</li></router-link>
              <router-link to="/contact-us"><li>{{ $t('footer.contactUs') }}</li></router-link>
            </ul>
          </div>
        </b-col>
        <b-col cols="7" sm="6" md="6" lg="4">
          <div class="footer-links">
            <ul>
              <router-link to="/ground-handling"><li>{{ $t('footer.groundHandling') }}</li></router-link>
              <router-link to="/aeronautical-operations-center"><li>{{ $t('footer.aeroOperCenter') }}</li></router-link>
              <router-link to="/travel-services"><li>{{ $t('footer.travelServices') }}</li></router-link>
            </ul>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4">
          <div class="footer-links_social">
            <ul>
              <li><a href="#"><font-awesome-icon icon="fa-brands fa-twitter" /></a></li>
              <li><a href="#"><font-awesome-icon icon="fa-brands fa-facebook" /></a></li>
              <li><a href="#"><font-awesome-icon icon="fa-brands fa-instagram" /></a></li>
            </ul>
          </div>
          <div class="footer-links">
            <ul>
              <li>{{ $t('footer.address') }}</li>
              <li>{{ $t('footer.phone') }}</li>
              <li>{{ $t('footer.phone2') }}</li>
              <li>{{ $t('footer.email') }}</li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: 'FooterItem'
  }
</script>

<style lang="scss">
</style>
