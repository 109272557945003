// FontAwesome
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faUserSecret, faGlobe, faChevronDown } from '@fortawesome/free-solid-svg-icons'
// import {  } from '@fortawesome/free-regular-svg-icons'
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faTwitter, faFacebook, faInstagram, faUserSecret, faGlobe, faChevronDown)

export default library
