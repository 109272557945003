const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue'),
  },
  {
    path: '/ground-handling',
    name: 'ground-handling',
    component: () => import('@/views/GroundHandling.vue'),
  },
  {
    path: '/aeronautical-operations-center',
    name: 'aeronautical-operations-center',
    component: () => import('@/views/AeronauticalOperationsCenter.vue'),
  },
  {
    path: '/travel-services',
    name: 'travel-services',
    component: () => import('@/views/TravelServices.vue'),
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/ContactUs.vue'),
  }
]

export default routes 